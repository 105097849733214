import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IConfigData } from '../../../../interfaces';
import MoreVert from '@material-ui/icons/MoreVert';
import { useStyles } from '../../../../styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ContentCopyOutlinedIcon from '@material-ui/icons/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { DateTime } from 'luxon';
import { capitalizeFirstLetter } from '../../utils';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ViewConfig from './ViewConfigPopup';

export interface IManageConfigTableProps {
  configData: IConfigData[];
  refreshTime: string;
  onRun: Function;
  onEdit: Function;
  onDuplicate: Function;
  onDelete: Function;
  sort: string;
  onSort: Function;
  isToolAdmin: boolean;
  onConfigClick: Function;
}

const ManageConfigTable = ({
  configData,
  refreshTime,
  onRun,
  onEdit,
  onDuplicate,
  onDelete,
  sort,
  onSort,
  isToolAdmin,
  onConfigClick,
}: IManageConfigTableProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const emptyPopupState = {
    open: false,
    onClose: () => { },
    popupContent: {},
  };

  const [viewConfigPopupDetails, setViewConfigPopupDetails] = useState({
    ...emptyPopupState,
  });

  const handleCloseConfigDetailsPopup = () => {
    setViewConfigPopupDetails(prevState => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleViewConfigClicked = (configData: any) => {
    setViewConfigPopupDetails({
      open: true,
      onClose: () => {
        handleCloseConfigDetailsPopup();
      },
      popupContent: configData,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = () => {
    onSort(sort === 'descending' ? 'ascending' : 'descending');
  };

  const [menuOptions, setMenuOptions] = useState<any>([]);

  const handleTheeDotsClicked = (event: any, config: any) => {
    setMenuOptions([
      {
        icon: <VisibilityOutlinedIcon className={classes.iconInMoreMenu} />,
        title: 'View',
        onClick: () => {
          handleViewConfigClicked(config);
          setAnchorEl(null);
        },
      },
      {
        icon: <EditOutlinedIcon className={classes.iconInMoreMenu} />,
        title: 'Edit',
        onClick: () => {
          onEdit(config.devxConfigId);
          setAnchorEl(null);
        },
      },
      {
        icon: <ContentCopyOutlinedIcon className={classes.iconInMoreMenu} />,
        title: 'Duplicate',
        onClick: () => {
          onDuplicate(config);
          setAnchorEl(null);
        },
      },
      {
        icon: <DeleteOutlineOutlinedIcon className={classes.iconInMoreMenu} />,
        title: 'Delete',
        onClick: () => {
          onDelete(config.devxConfigId);
          setAnchorEl(null);
        },
      },
    ]);
    setAnchorEl(event.currentTarget);
  };
  console.log(configData)

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          size="small"
          className={classes.table}
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow classes={{ root: classes.tableHead }}>
              <TableCell className={classes.dateTimeTableCell}>
                <Typography
                  classes={{ root: classes.tableHeadCell }}
                  variant="subtitle2"
                >
                  Config name
                </Typography>
                <IconButton
                  className={classes.actionButtonRun}
                  onClick={() => handleSort()}
                >
                  {sort === 'descending' ? (
                    <ArrowDownward fontSize="small" />
                  ) : (
                    <ArrowUpward fontSize="small" />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                <Typography
                  classes={{ root: classes.tableHeadCell }}
                  variant="subtitle2"
                >
                  Config id
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  classes={{ root: classes.tableHeadCell }}
                  variant="subtitle2"
                >
                  Run by
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  classes={{ root: classes.tableHeadCell }}
                  variant="subtitle2"
                >
                  Last run on
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  classes={{ root: classes.tableHeadCell }}
                  variant="subtitle2"
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {configData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((config: any) => (
                <TableRow
                  key={config.devxConfigId}
                  classes={{ root: classes.configTableRow }}
                >
                  <TableCell
                    classes={{
                      root: `${classes.tableBodyCell} ${classes.clickableTableCell}`,
                    }}
                    onClick={() => onConfigClick(config.configName)}
                  >
                    <Typography variant="subtitle2">
                      {config.configName}
                    </Typography>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableBodyCell }}>
                    {config.devxConfigId}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableBodyCell }}>
                    {config.requestUser}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableBodyCell }}>
                    {config.requestTime
                      ? DateTime.fromISO(config.requestTime).toFormat(
                        'dd MMM yyyy, hh:mm a',
                      )
                      : '-'}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableBodyCell }}>
                    <div className={classes.configStatus}>
                      {capitalizeFirstLetter(config.status)}
                    </div>
                  </TableCell>
                  <TableCell className={classes.actionButtonInTableCell}>
                    {isToolAdmin ? (
                      <>
                        {config.config.globalTraffic && config.config.globalTraffic.length > 0 && config.config.globalTraffic.find((config: any) => config.region === 'EMEA') && (
                          <IconButton classes={{ root: classes.actionButtonRun }}>
                            <Tooltip
                              title="EMEA is currently under review by GCSO; availability may be affected."
                              arrow={true}
                              placement="bottom-end"
                            >
                              <InfoOutlinedIcon fontSize="medium" />
                            </Tooltip>
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => {
                            onRun(config.devxConfigId);
                          }}
                          disabled={
                            config?.status.toLowerCase() !== 'config created' &&
                            config?.status.toLowerCase() !== 'ended' &&
                            config?.status.toLowerCase() !== 'failed'
                          }
                          classes={{ root: classes.actionButtonRun }}
                        >
                          <Tooltip
                            title="Run config"
                            arrow={true}
                            placement="bottom-end"
                          >
                            <PlayArrowIcon fontSize="medium" />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={event =>
                            handleTheeDotsClicked(event, config)
                          }
                          classes={{ root: classes.actionButtonRun }}
                        >
                          <MoreVert fontSize="medium" />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        onClick={() => handleViewConfigClicked(config)}
                        classes={{ root: classes.actionButtonRun }}
                      >
                        <Tooltip
                          title="View config"
                          arrow={true}
                          placement="bottom-end"
                        >
                          <VisibilityOutlinedIcon
                            className={classes.iconInMoreMenu}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {configData.length === 0 && (
              <TableCell>
                <Typography>No configs found.</Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={configData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            root: classes.tablePaginationRoot,
            actions: classes.tablePaginationActions,
            selectRoot: classes.tablePaginationSelect,
            spacer: classes.tablePaginationBar,
          }}
        />
      </TableContainer>
      <Typography className={classes.updatedTime}>
        Refreshed at{' '}
        {refreshTime &&
          DateTime.fromISO(refreshTime).toFormat('dd MMM yyyy, hh:mm a')}
      </Typography>
      <Menu
        id="collapsible-table-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {menuOptions.map((option: any) => {
          return (
            <MenuItem onClick={option.onClick}>
              {option.icon}
              {option.title}
            </MenuItem>
          );
        })}
      </Menu>
      {viewConfigPopupDetails?.open && (
        <ViewConfig {...viewConfigPopupDetails} />
      )}
    </>
  );
};

export default ManageConfigTable;
