import { useEffect, useState } from 'react';

export const useFilteredEntries = (
  data: any,
  category: string,
  searchText: string,
) => {
  const [filteredEntries, setFilteredEntries] = useState();

  useEffect(() => {
    let localEntries;
    if (category === 'All categories') localEntries = { ...data };
    else {
      localEntries = data?.filter((entry: any) => entry.category === category);
    }
    function matchFilter(filter?: string): (entry: any) => boolean {
      const terms = filter
        ?.toLocaleLowerCase('en-US')
        .split(/\s/)
        .map(e => e.trim())
        .filter(Boolean);

      if (!terms?.length) {
        return () => true;
      }

      return entry => {
        const text = `${entry.title} ${
          entry?.description || ''
        }`.toLocaleLowerCase('en-US');
        return terms.every(term => text.includes(term));
      };
    }
    localEntries = Object.values(localEntries)?.filter(matchFilter(searchText));
    setFilteredEntries(localEntries);
  }, [data, category, searchText]);

  return { filteredEntries };
};
