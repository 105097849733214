import { Progress } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import React, { useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import Radar from './Radar';
import { useFilteredEntries } from '../hooks/useFilteredEntries';

const useTechRadarLoader = (id: string | undefined) => {
  const errorApi = useApi(errorApiRef);
  const techRadarApi = useApi(techRadarApiRef);

  const { error, value, loading } = useAsync(
    async () => techRadarApi.load(id),
    [techRadarApi, id],
  );

  useEffect(() => {
    if (error) {
      errorApi.post(error);
    }
  }, [error, errorApi]);

  return { loading, value, error };
};

/**
 * Properties of {@link TechRadarComponent}
 *
 * @public
 */
export interface TechRadarComponentProps {
  id?: string;
  width: number;
  height: number;
  svgProps?: object;
  searchText?: string;
  filterOption: Object;
  onSetCategories: Function;
}

export function RadarComponent(props: TechRadarComponentProps) {
  const { loading, error, value: data } = useTechRadarLoader(props.id);

  const { filteredEntries } = useFilteredEntries(
    data?.entries,
    props.filterOption?.category,
    props.searchText || '',
  );

  useEffect(() => {
    if (data?.entries) {
      props.onSetCategories([
        'All categories',
        ...new Set(data?.entries.map((entry: any) => entry.category)),
      ]);
    }
  }, [data]);

/*   const mapToEntries = () => {
    return Object.values(filteredEntries).map((entry: any) => ({
      id: entry.name,
      category: entry.category,
      quadrant: entry.quadrant,
      title: entry.title,
      ring: entry.ring,
      description: entry.description,
      url: entry.url,
      links: entry.links,
    }));
  }; */

  return (
    <>
      {loading && <Progress />}
      {!loading && !error && data && (
        <Radar
          {...props}
          rings={data.rings}
          quadrants={data.quadrants}
          entries={filteredEntries}
        />
      )}
    </>
  );
}
